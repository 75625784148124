import '@inovua/reactdatagrid-community/index.css';
import { Toast } from '@anatoscope/circlestorybook';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authActions } from '../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import './private-app.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api.ts';
import { useEffect, useState } from 'react';
import { resetAll } from '../../store';

import { faList, faHeadset, faUser, faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import { Menu } from './menu/Menu.tsx';
import { forceRefreshToken } from '../../services/firebase.services.tsx';
import { ToastType } from '../../enum/error.ts';

const PrivateApp = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const toast = useAppSelector(toastSelector);
  const location = useLocation();

  const [isResetStore, setIsResetStore] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
  }, []);

  const contentMainMenu = {
    upper: [
      {
        label: t('link.myTreatments'),
        type: 'nav',
        link: '/treatments',
        icon: faList
      },
      {
        label: t('link.hotline'),
        type: 'nav',
        link: '/hotline',
        icon: faHeadset
      }
    ],
    lower: [
      {
        label: t('link.myProfile'),
        type: 'nav',
        link: '/profile',
        icon: faUser
      },
      {
        label: t('link.logout'),
        type: 'button',
        onClick: () => {
          dispatch(authActions.logout());
        },
        icon: faPowerOff,
        'data-cy': 'logout'
      }
    ]
  };

  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });

  return (
    isResetStore && (
      <div className="private-app" data-cy="privateApp">
        {toast && (
          <Toast
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
            type={toast.type}
          />
        )}
        <Menu items={contentMainMenu} zIndex={3} />
        <main className="private-app__main">
          <Outlet />
        </main>
        {location.pathname === '/treatments' && <div className="dashboard-bottom-layer"></div>}
      </div>
    )
  );
};
export default PrivateApp;
