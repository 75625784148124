import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken, isCurrentUser } from './firebase.services.tsx';
import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { backOff, BackoffOptions } from 'exponential-backoff';
import { isManuApi, isOrdersApi, isUsersApi } from '../utils/utils.tsx';

/**
 * Allow to switch between local back or gateway instance if VITE_MS are defined.
 * @param args
 */
const switchLocalEnv = (args: string | FetchArgs) => {
  const url: string = typeof args === 'string' ? args : args.url;

  let subUrl = new URL(`http://localhost:3011${url}`).pathname;
  subUrl = subUrl.split('/')?.[1];

  // construct a dynamically generated portion of the url
  let adjustedUrl: string = `${import.meta.env.VITE_API_GCP_GATEWAY}${url}`;

  if (import.meta.env.VITE_MS_ORDERS_LOCAL && isOrdersApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_ORDERS_LOCAL}${url}`;
  } else if (import.meta.env.VITE_MS_USERS_LOCAL && isUsersApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_USERS_LOCAL}${url}`;
  } else if (import.meta.env.VITE_MS_MANU_LOCAL && isManuApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_MANU_LOCAL}${url}`;
  }

  return typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
};

/**
 * If VITE_MS are defined, the function switch from Authorization bearer to x-forwarded-authorization to work like gateway does.
 *
 * @param headers
 * @param token
 */
const setAuthorizationBearerHeader = (headers: Headers, token?: string) => {
  if (
    import.meta.env.VITE_MS_ORDERS_LOCAL ||
    import.meta.env.VITE_MS_USERS_LOCAL ||
    import.meta.env.VITE_MS_MANU_LOCAL
  ) {
    headers.set('x-forwarded-authorization', `Bearer ${token}`);
  } else {
    headers.set('Authorization', `Bearer ${token}`);
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_GCP_GATEWAY,
  prepareHeaders: async (headers: Headers, api: Pick<BaseQueryApi, 'endpoint'>) => {
    if (api.endpoint === 'uploadToStorage') {
      return headers;
    }
    headers.set('x-api-key', import.meta.env.VITE_API_KEY_GCP_GATEWAY);
    if (isCurrentUser()) {
      const token = await getToken();
      setAuthorizationBearerHeader(headers, token);
    }
    return headers;
  }
});

const exponentialBackOffBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const backOffOptions: BackoffOptions = {
    maxDelay: 1000,
    numOfAttempts: 5,
    timeMultiple: 1
  };
  return await backOff(async () => {
    const newArgs = switchLocalEnv(args);
    const result = await baseQuery(newArgs, api, extraOptions);
    if (result.error && 'status' in result.error && result.error.status === 504) {
      throw new Error('504 Gateway Timeout');
    }
    return result;
  }, backOffOptions);
};

export const api = createApi({
  baseQuery: exponentialBackOffBaseQuery,
  tagTypes: ['Orders', 'Order', 'UserInfo', 'OneOrder'],
  endpoints: () => ({})
});
