import { Button, Fieldset, RadioList, TextField } from '@anatoscope/circlestorybook';
import styles from './identification-form.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DentistRole } from '../../../enum/user';
import { useTranslation } from 'react-i18next';
import { userSelector } from '../../../store/users/users.selectors';
import { usersActions } from '../../../store/users/users.reducer';
import useForm from '../../../utils/useForm';
import { useEffect, useMemo } from 'react';
import { authenticatedUserSelector } from '../../../store/auth/auth.selectors';
import { useAddUserMutation } from '../../../services/user.api.services';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../enum/color';

type Props = {
  nextCallback?: () => void;
  cancelCallback?: () => void;
  previousCallback?: () => void;
  submitCallback?: () => void;
  className?: string;
  withFieldset?: boolean;
};

const IdentificationForm = ({
  nextCallback,
  cancelCallback,
  previousCallback,
  submitCallback,
  className,
  withFieldset
}: Props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  // We need infos from the authenticatedUser if there is one
  // (either we are creating a dentist from "my profile", of registering)
  // to link the new user to the clinic/lab of the authenticated user
  const authenticatedUser = useAppSelector(authenticatedUserSelector);

  // share results across mutation hook instances using the fixedCacheKey option
  const [addUser, { isLoading: isLoadingAddUser, isSuccess: isUserAdded }] = useAddUserMutation({
    fixedCacheKey: 'shared-add-user'
  });

  useEffect(() => {
    if (isUserAdded && submitCallback) {
      dispatch(
        feedbackActions.setToast({
          message: t('user.addUser.success'),
          type: 'success'
        })
      );
      submitCallback();
    }
  }, [isUserAdded, submitCallback]);

  const addNewUser = async () => {
    const newUser = {
      role: values.role as DentistRole.DENTIST,
      email: values.email as string,
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      phoneNumber: values.phoneNumber as string,
      isAllowedToOrder: true,
      language: authenticatedUser.language,
      dentalNotation: authenticatedUser.dentalNotation,
      clinic: {
        id: authenticatedUser?.clinic?.id
      },
      laboratory: {
        id: authenticatedUser?.laboratory?.id
      }
    };
    await addUser(newUser);
  };

  const onSubmit = async () => {
    if (!nextCallback) {
      await addNewUser();
    } else if (nextCallback) {
      saveIdentificationInfo();
      nextCallback();
    }
  };

  const roles = [
    {
      label: t(`user.roles.${DentistRole.DENTIST.toLowerCase()}`, { ns: 'common' }),
      value: DentistRole.DENTIST
    },
    {
      label: t(`user.roles.${DentistRole.ASSISTANT.toLowerCase()}`, { ns: 'common' }),
      value: DentistRole.ASSISTANT
    }
  ];

  const saveIdentificationInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values
      })
    );
  };

  const handlePreviousButton = () => {
    saveIdentificationInfo();
    if (previousCallback) previousCallback();
  };

  const initUser = useMemo(
    () => ({
      role: user?.role ? user?.role : DentistRole.DENTIST,
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.phoneNumber || ''
    }),
    [user]
  );

  const { values, errors, setValues, handleSubmit, handleSelect, handleBlur, handleChange } =
    useForm(initUser, onSubmit);

  useEffect(() => {
    setValues(initUser);
  }, [initUser, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset
        withBorder={withFieldset}
        size="m"
        className={[
          styles['identification-form__form__fieldset'],
          !withFieldset && styles['identification-form__form__fieldset--no-padding'],
          className
        ].join(' ')}>
        <>
          <RadioList
            displayedInRow={true}
            title={t('user.addUser.identification.role')}
            options={roles}
            name="role"
            selectedValue={values.role}
            onClick={(newValue: DentistRole) => handleSelect(newValue, 'role')}
            className={styles['identification-form__form__fieldset__input']}
            radius="full"
          />
          <TextField
            label={t('user.addUser.identification.email')}
            id={'email'}
            name="email"
            value={values.email}
            type={'email'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors?.email ? t('user.addUser.identification.emailMandatory') : undefined}
            variant={errors?.email ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['identification-form__form__fieldset__input']}
            placeholder="email@circle.com"
            radius="full"
          />
          <TextField
            id={'firstName'}
            label={t('user.addUser.identification.firstName')}
            value={values.firstName}
            name="firstName"
            type={'text'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.firstName ? t('user.addUser.identification.firstNameMandatory') : undefined
            }
            variant={errors?.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['identification-form__form__fieldset__input']}
            placeholder="John"
            radius="full"
          />
          <TextField
            id={'lastName'}
            label={t('user.addUser.identification.lastName')}
            value={values.lastName}
            name="lastName"
            type={'text'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.lastName ? t('user.addUser.identification.lastNameMandatory') : undefined
            }
            variant={errors?.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['identification-form__form__fieldset__input']}
            placeholder="Smith"
            radius="full"
          />
          <TextField
            label={t('user.addUser.identification.phone')}
            id={'phone'}
            name="phoneNumber"
            value={values.phoneNumber}
            type={'tel'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.phoneNumber ? t('user.addUser.identification.phoneMandatory') : undefined
            }
            variant={errors?.phoneNumber ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            placeholder={t('user.addUser.identification.placeholder.phone')}
            radius="full"
          />
        </>
      </Fieldset>
      <div className="form__submit-button form__submit-button--double">
        {previousCallback && (
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="secondary"
            onClick={handlePreviousButton}
            iconLeft="fa-chevron-left"
          />
        )}
        {cancelCallback && (
          <Button
            category="secondary"
            iconLeft="fa-xmark"
            label={t('action.cancel', { ns: 'common' })}
            onClick={cancelCallback}
          />
        )}
        {submitCallback ? (
          <Button
            data-cy="submit-add-user"
            label={t('user.action.addUser')}
            type="submit"
            variant={ColorPropsEnum.SUCCESS}
            isLoading={isLoadingAddUser}
            iconLeft="fa-check"
          />
        ) : (
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            iconRight="fa-chevron-right"
          />
        )}
      </div>
    </form>
  );
};
export default IdentificationForm;
